import React, { Component } from 'react';
import { connect } from 'react-redux';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';

import { requestMonimalzProducts } from '../actions/shopActions';

import Layout from '../components/layout/layout';
import { Footer, pageWithNavigation } from '../components/common';
import { SecuredPages } from '../components/common/page/securedPages';
import Checkout from '../components/checkout/index';

class CheckoutShippingPage extends Component {
  constructor(props) {
    super(props);
    this.state = { stripe: null };
  }

  componentDidMount() {
    const { requestMonimalzProducts } = this.props;

    if (window.Stripe) {
      this.setState({
        stripe: window.Stripe(process.env.GATSBY_STRIPE_API_KEY),
      });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        this.setState({
          stripe: window.Stripe(process.env.GATSBY_STRIPE_API_KEY),
        });
      });
    }
    requestMonimalzProducts();
  }

  render() {
    const { data } = this.props;
    const { stripe } = this.state;

    return (
      <Layout
        title={data.wordings.fr.home.page_title}
        description={data.wordings.fr.home.description}
      >
        <Checkout stripe={stripe} page="CHECKOUT_SHIPPING" />
        <Footer />
      </Layout>
    );
  }
}

CheckoutShippingPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const query = graphql`
  query CheckoutShippingQuery {
    wordings {
      fr {
        home {
          page_title
          description
        }
      }
    }
  }
`;

export default connect(
  null,
  { requestMonimalzProducts }
)(pageWithNavigation(SecuredPages(CheckoutShippingPage)));
